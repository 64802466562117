import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { ScorecardEntity } from '@/entities/scorecard'
import { INPSSummary, INPSTrendGraph, IResponse } from '@/entities'
import { getBadgeTotalCount, getMyTeam, getScorecard } from '@/api/scorecard'
import { getResponses, getResponsesByUserId } from '@/api/responses'
import { getNPSSummary, getWeeklyNPSTrendGraph } from '@/api/nps'
import { time } from '@/utils/time'
import { IRecipient } from '@/entities/notices'
import { getChatResponses } from '@/api/chat'
import { sleep } from '@/utils/async'

export const OFFLINE_RESPONSE_COUNT = 100

export interface ScorecardState {
    scorecard: ScorecardEntity | null
    scorecardLoading: boolean
    scorecards: ScorecardEntity[] | Array<any>
    responses: IResponse[]
    scorecardResponsesMap: {
        [value: string]: IResponse[]
    }
    badgeTotalMap: {
        [value: string]: number
    }
    slide: string
    scorecardNpsTrendGraph: INPSTrendGraph
    companyNpsSummary: INPSSummary
    myTeam: IRecipient[]
}

export const state: ScorecardState = {
    scorecard: null,
    scorecardLoading: false,
    scorecards: [
        {
            id: 1,
            label: 'Scorecard 1',
            value: 'topic',
        },
        {
            id: 2,
            label: 'Scorecard 2',
            value: 'country',
        },
    ],
    responses: [],
    scorecardResponsesMap: {},
    badgeTotalMap: {},
    slide: 'left',
    scorecardNpsTrendGraph: {
        labels: [],
        hoverDate: [],
        nps: [],
        totalResponded: [],
        validMonths: 0,
        trendGraphLabel: '',
        period: '',
        isNoData: true,
    },
    companyNpsSummary: {
        nps: 0,
        change: 0,
        total: 0,
    },
    myTeam: [],
}

const getters: GetterTree<ScorecardState, any> = {
    scorecard({ scorecard }) {
        return scorecard
    },

    scorecards({ scorecards }) {
        return scorecards
    },

    // if scorecard needs update
    scorecardOutdated({ scorecard }) {
        return !scorecard || time() - scorecard.last_updated > 10
    },

    scorecardLoading({ scorecardLoading }) {
        return scorecardLoading
    },

    responses({ responses }) {
        return responses
    },

    scorecardResponsesMap({ scorecardResponsesMap }) {
        return scorecardResponsesMap
    },

    badgeTotalMap({ badgeTotalMap }) {
        return badgeTotalMap
    },

    slide({ slide }) {
        return slide
    },

    scorecardNpsTrendGraph({ scorecardNpsTrendGraph }) {
        return scorecardNpsTrendGraph
    },

    companyNpsSummary({ companyNpsSummary }) {
        return companyNpsSummary
    },

    locationText({ scorecard }) {
        if (!scorecard || !scorecard?.names || !scorecard.names.length) {
            return ''
        }
        if (scorecard.names.length === 1) {
            return scorecard.rankFieldLabel
        }
        return `${scorecard.names.length} ${scorecard.rankFieldLabel}s`
    },

    myTeam({ myTeam }) {
        return myTeam ?? []
    },
}

const actions: ActionTree<ScorecardState, any> = {
    async loadScorecard({ commit }, userId) {
        commit('setScorecardLoading', true)

        const { data } = await getScorecard(userId)
        commit('setScorecard', data)
        commit('setScorecardLoading', false)
    },

    async loadMyTeam({ commit }) {
        const { data } = await getMyTeam()
        commit('setMyTeam', data)
    },

    async loadResponses({ commit, state }) {
        if (!state.scorecard) {
            commit('setResponses', [])
            return
        }
        const { data } = await getResponses(
            {
                time_unit: 'any',
                time_value: '',
                filter_rules: [
                    {
                        operator: 'isnotempty',
                        column: 'comment',
                        value: ['whatever'],
                    },
                    {
                        operator: 'isnotempty',
                        column: state.scorecard.scorecardField,
                        value: ['whatever'],
                    },
                ],
            },
            {
                pagesize: 20,
                offset: 0,
                // customFields: questionKey ? [questionKey] : [],
                withtotal: false,
                applyScorecardLockedFilters: 1,
            }
        )
        commit('setResponses', data)
    },

    async loadResponsesForBadge(
        { commit, state, rootGetters },
        { type, value }
    ) {
        if (!state.scorecard) {
            return
        }
        if (state.scorecard.badges[type][value]?.ids.length) {
            const promoFilterVal = state.scorecard.isFiveScoreQuestionType
                ? ['starpos']
                : ['promoters']
            const filterOperator = type === 'positive' ? 'in' : 'notin'
            const { data } = await getResponsesByUserId(
                state.scorecard.user.id,
                {
                    time_unit: 'any', // ignore time
                    time_value: '1',
                    filter_rules: [
                        {
                            column: state.scorecard.scorecardField,
                            operator: 'in',
                            value: [value],
                        },
                        {
                            column: 'rating',
                            operator: filterOperator,
                            value: promoFilterVal,
                        },
                    ],
                },
                {
                    pagesize: !rootGetters.hasNetworkConnection
                        ? OFFLINE_RESPONSE_COUNT
                        : 10,
                    offset: 0,
                    // customFields: questionKey ? [questionKey] : [],
                    withtotal: false,
                    applyScorecardLockedFilters: 1,
                }
            )
            commit('setScorecardResponsesMap', {
                value: value + '_' + type,
                responses: data,
            })
        } else {
            commit('setScorecardResponsesMap', {
                value: value + '_' + type,
                responses: [],
            })
        }
    },

    async loadTotalCountForBadge({ commit, state }, { type, value, userId }) {
        if (!state.scorecard) {
            return
        }
        const { data } = await getBadgeTotalCount(value, type, userId)
        commit('setBadgeTotalMap', { value: value + '_' + type, data })
    },

    setSlide({ commit }, slide: string) {
        commit('setSlide', slide)
    },

    async loadNpsTrendGraph({ commit }, userId?: string) {
        const { data } = await getWeeklyNPSTrendGraph(userId)
        commit('setScorecardNpsTrendGraph', { scorecardNpsTrendGraph: data })
    },

    async loadCompanyNpsSummary({ commit }, userId?: string) {
        const { data } = await getNPSSummary(1, 'month', 1, userId)
        commit('setCompanyNpsSummary', data)
    },

    // load all data and cache it for offline
    async loadOfflineDataForScorecard() {
        // 1. load scorecard
        const { data } = await getScorecard()
        // 2. load positive badges & its responses
        //    do it sequentially so we don't put too much load on the server at one time
        for (const type of ['positive', 'negative']) {
            for (const value in data.badges[type]) {
                if (type === 'positive') {
                    await getBadgeTotalCount(value, type)
                }

                await getResponses(
                    {
                        time_unit: 'any', // ignore time
                        time_value: '1',
                        filter_rules: [
                            {
                                column: 'id',
                                operator: 'in',
                                value: data.badges[type][value].ids,
                            },
                        ],
                    },
                    {
                        pagesize: OFFLINE_RESPONSE_COUNT, // make this very big to load all in one batch
                        offset: 0,
                        withtotal: false,
                        applyScorecardLockedFilters: 1,
                    }
                )
                // load chat data as well
                data.badges[type][value].ids.map(
                    async (id) => await getChatResponses(parseInt(id))
                )
            }
        }
        // trend graph
        await getWeeklyNPSTrendGraph()
        await getNPSSummary(1, 'month', 1)
    },
}

const mutations: MutationTree<ScorecardState> = {
    setScorecard(state, scorecard: ScorecardEntity) {
        state.scorecard = scorecard
    },

    setScorecards(state, scorecards: ScorecardEntity[]) {
        state.scorecards = scorecards
    },

    setMyTeam(state, myTeam: IRecipient[]) {
        state.myTeam = myTeam
    },

    setScorecardLoading(state, scorecardLoading: boolean) {
        state.scorecardLoading = scorecardLoading
    },

    setResponses(state, responses: IResponse[]) {
        state.responses = responses
    },

    setScorecardResponsesMap(state, { value, responses }) {
        state.scorecardResponsesMap = {
            ...state.scorecardResponsesMap,
            [value]: responses,
        }
    },

    setBadgeTotalMap(state, { value, data }) {
        state.badgeTotalMap = { ...state.badgeTotalMap, [value]: data }
    },

    setSlide(state, slide: string) {
        state.slide = slide
    },

    setScorecardNpsTrendGraph(state, { scorecardNpsTrendGraph }) {
        state.scorecardNpsTrendGraph = scorecardNpsTrendGraph
    },

    setCompanyNpsSummary(state, data: INPSSummary) {
        state.companyNpsSummary = data
    },
}

export interface ScorecardResponseRateData {
    graph: {
        labels: []
        scorecard: []
        not_scorecard: []
    }
    survey_with: []
    survey_without: []
    survey_with_total: number
    survey_without_total: number
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
