export interface ICompanyConfig {
    target?: string
    target_fivestar?: string
    target_csat?: string
    target_enabled?: boolean
    target_fivestar_enabled?: boolean
    target_csat_enabled?: boolean
    reward_period?: number
    reward_period_fivestar?: number
    reward_period_csat?: number
    reward?: string
    reward_fivestar?: string
    reward_csat?: string
    reward_enabled?: boolean
    reward_fivestar_enabled?: boolean
    reward_csat_enabled?: boolean
}

export enum CompanyAttributes {
    industry = 'industry',
    calculated_fields_stats = 'calculated_fields_stats',
    leaderboard_configuration = 'leaderboard_configuration',
    target = 'target',
    target_fivestar = 'target_fivestar',
    target_csat = 'target_csat',
    target_enabled = 'target_enabled',
    target_fivestar_enabled = 'target_fivestar_enabled',
    target_csat_enabled = 'target_csat_enabled',
}

export enum CompanyFeatures {
    anom = 'anom',
    approved = 'approved',
    beta_apps_enabled = 'beta_apps_enabled',
    browsertracking = 'browsertracking',
    candelete = 'candelete',
    clearbit = 'clearbit',
    disable_botdetection = 'disable_botdetection',
    disable_activation_emails_on_invite = 'disable_activation_emails_on_invite',
    disable_magic_link_login = 'disable_magic_link_login',
    disable_sending_domain = 'disable_sending_domain',
    disableemaillogin = 'disableemaillogin',
    display_encrypted_message = 'display_encrypted_message',
    display_trigger_field_replacement = 'display_trigger_field_replacement',
    email_hash_is_optional = 'email_hash_is_optional',
    enable_insights_repeat_customers = 'enable_insights_repeat_customers',
    enable_insights_retention = 'enable_insights_retention',
    enable_insights_tenure = 'enable_insights_tenure',
    enable_insights_v1 = 'enable_insights_v1',
    enable_launch_pad = 'enable_launch_pad',
    enable_scorecard_ranking = 'enable_scorecard_ranking',
    enable_sso_configurations = 'enable_sso_configurations',
    enable_test_mode = 'enable_test_mode',
    enable_vue_workflow_ui = 'enable_vue_workflow_ui',
    force_sms = 'force_sms',
    has_achievement_interactions = 'has_achievement_interactions',
    has_advanced_case_management = 'has_advanced_case_management',
    has_ai_conversational_surveys = 'has_ai_conversational_surveys',
    has_ai_themes = 'has_ai_themes',
    has_async_api_contact_add = 'has_async_api_contact_add',
    has_async_api_contact_add_performance = 'has_async_api_contact_add_performance',
    has_auditlog = 'has_auditlog',
    has_detractor_tags = 'has_detractor_tags',
    has_display_csat_as_percentage = 'has_display_csat_as_percentage',
    has_elastic2 = 'has_elastic2',
    has_enforce_mfa = 'has_enforce_mfa',
    has_hashtag_whitelist = 'has_hashtag_whitelist',
    has_frontline_onboard = 'has_frontline_onboard',
    has_frontline_only = 'has_frontline_only',
    has_huddle = 'has_huddle',
    has_insights_desktop_engagement = 'has_insights_desktop_engagement',
    has_insights_desktop_team_updates = 'has_insights_desktop_team_updates',
    has_insights_v3 = 'has_insights_v3',
    has_isnicely_ip_whitelist = 'has_isnicely_ip_whitelist',
    has_lockfilters = 'has_lockfilters',
    has_lead_with_any_question = 'has_lead_with_any_question',
    has_mfa = 'has_mfa',
    has_notices = 'has_notices',
    has_nps_widget = 'has_nps_widget',
    has_recaptcha = 'has_recaptcha',
    has_reviews = 'has_reviews',
    has_role_group = 'has_role_group',
    has_scorecard = 'has_scorecard',
    has_scorecard_statuses = 'has_scorecard_statuses',
    has_shared_widget = 'has_shared_widget',
    has_smart_notifications = 'has_smart_notifications',
    has_smart_rank = 'has_smart_rank',
    has_soften_contact_rules = 'has_soften_contact_rules',
    has_workflow_apps = 'has_workflow_apps',
    hide_google_signin = 'hide_google_signin',
    hide_legacy_alerts = 'hide_legacy_alerts',
    hide_trigger_field = 'hide_trigger_field',
    inapp = 'inapp',
    infocare = 'infocare',
    log_inapp_activity = 'log_inapp_activity',
    nobranding = 'nobranding',
    noscoreupdate = 'noscoreupdate',
    display_location_message = 'display_location_message',
    purge_people = 'purge_people',
    purge_people_days = 'purge_people_days',
    purge_people_comments = 'purge_people_comments',
    purge_active_contacts = 'purge_active_contacts',
    ssl = 'ssl',
    supportoptout = 'supportoptout',
    update_elastic_without_disque = 'update_elastic_without_disque',
    userrolesui = 'userrolesui',
}

export type QuestionType = 'nps' | 'csat' | 'fivestar'
