<template>
    <div class="summary-header">
        <div
            ref="sliderContainer"
            class="slider responses"
            @scroll="handleScroll"
        >
            <div class="slide nps-summary">
                <SimpleSpinner
                    v-if="loading"
                    size="small"
                    class="spinner"
                    line-fg-color="#B338A3"
                ></SimpleSpinner>
                <template v-else>
                    <div class="summary-title">{{ mobileQuestionType }}</div>
                    <div class="nps-big">
                        {{ Number(npsSummary?.nps).toFixed(1) }}
                    </div>
                    <div class="nps-change">
                        Change {{ Number(npsSummary?.change).toFixed(1) }}
                        <img
                            v-if="npsChange"
                            class="trend-caret"
                            :src="npsChangeIcon"
                        />
                    </div>
                </template>
            </div>
            <div class="slide">
                <SimpleSpinner
                    v-if="loading"
                    size="small"
                    class="spinner"
                    line-fg-color="#B338A3"
                ></SimpleSpinner>
                <template v-else>
                    <div class="summary-title">Responses</div>
                    <div class="stats">
                        <div class="stat-line">
                            <div>Total</div>
                            <div>{{ npsStatsSummary?.total }}</div>
                        </div>
                        <div class="stat-line">
                            <div>{{ metricLabel('PROMOTER') }}</div>
                            <div>{{ npsStatsSummary?.promoter }}</div>
                        </div>
                        <div class="stat-line">
                            <div>{{ metricLabel('PASSIVE') }}</div>
                            <div>{{ npsStatsSummary?.passive }}</div>
                        </div>
                        <div class="stat-line">
                            <div>{{ metricLabel('DETRACTOR') }}</div>
                            <div>{{ npsStatsSummary?.detractor }}</div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="slide">
                <SimpleSpinner
                    v-if="loading"
                    size="small"
                    class="spinner"
                    line-fg-color="#B338A3"
                ></SimpleSpinner>
                <template v-else>
                    <div class="summary-title">Surveys</div>
                    <div class="stats">
                        <div class="stat-line">
                            <div>Sent</div>
                            <div>{{ npsStatsSummary?.sent }}</div>
                        </div>
                        <div class="stat-line">
                            <div>Opened</div>
                            <div>{{ npsStatsSummary?.opened }}</div>
                        </div>
                        <div class="stat-line">
                            <div>Responded</div>
                            <div>{{ npsStatsSummary?.responded }}</div>
                        </div>
                        <div class="stat-line">
                            <div>Response Rate</div>
                            <div>{{ npsStatsSummary?.responseRate }}%</div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="slide">
                <div class="summary-title">{{ trendGraphLabel }}</div>
                <div><HistoricGraph /></div>
            </div>
        </div>
        <div class="summary-dots">
            <div
                v-for="(dot, dotIdx) in slideNumber"
                :key="dotIdx"
                class="dot"
                :class="{ active: currentSlide === dot - 1 }"
            ></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Action, Getter } from 'vuex-facing-decorator'
import { Component, Watch, Vue } from 'vue-facing-decorator'
import HistoricGraph from '@/mobile/src/components/HistoricGraph.vue'
import SimpleSpinner from '@/components/SimpleSpinner.vue'
import IconChangeUp from '@/assets/img/Icons/leaderboard-up.svg'
import IconChangeDown from '@/assets/img/Icons/leaderboard-down.svg'
import { INPSStatsSummary, INPSSummary, INPSTrendGraph } from '@/entities'
import { FIVESCORE_TYPE, NPS_TYPE } from '@/domain/NPS'
import { ucfirst } from '@/utils/string'

@Component({
    components: {
        HistoricGraph,
        SimpleSpinner,
    },
})
export default class SummaryHeader extends Vue {
    @Action public updateNpsSummary
    @Action public updateNpsStatsSummary

    @Getter public mobileQuestionType
    @Getter public filterLastPatch
    @Getter public npsSummary!: INPSSummary
    @Getter public npsStatsSummary?: INPSStatsSummary
    @Getter public npsTrendGraph!: INPSTrendGraph
    @Getter public $companyVars
    @Getter
    hasNetworkConnection!: boolean

    private currentSlide = 0
    private slideNumber = 4
    private loading = false

    private mounted() {
        if (!this.npsSummary || !this.npsSummary.total) {
            this.onFilterChange()
        }
    }

    public get npsChange() {
        return this.npsSummary?.change
    }

    public get npsChangeIcon() {
        return this.npsChange >= 0 ? IconChangeUp : IconChangeDown
    }

    public get trendGraphLabel() {
        return this.npsTrendGraph.trendGraphLabel
    }

    get metricLabel() {
        return (scoreType) => {
            const mappings =
                this.mobileQuestionType === 'nps' ? NPS_TYPE : FIVESCORE_TYPE
            return ucfirst(mappings[scoreType])
        }
    }

    @Watch('filterLastPatch')
    @Watch('hasNetworkConnection')
    @Watch('mobileQuestionType')
    public async onFilterChange() {
        this.loading = true
        await this.updateNpsSummary(this.mobileQuestionType)
        await this.updateNpsStatsSummary(this.mobileQuestionType)
        this.loading = false
    }

    handleScroll() {
        const container = this.$refs.sliderContainer as HTMLElement
        this.currentSlide = 0
        if (container) {
            const scrollPercentage =
                container.scrollLeft / container.scrollWidth
            this.currentSlide = Math.round(this.slideNumber * scrollPercentage)
        }
    }
}
</script>

<style lang="less" scoped>
.summary-header {
    background-image: linear-gradient(
        328.74deg,
        #794bbf 0%,
        #b25fc6 50%,
        #c4a0bf 100%
    );
    color: #fff;
    padding: 5px 5px 28px 5px;

    scroll-snap-align: start;
}

.summary-title {
    font-size: 14px;
    text-align: center;
    flex: 0 1 auto;
    margin-top: 16px;
    text-transform: uppercase;
}
.nps-summary {
    text-align: center;
}

.nps-change {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nps-big {
    font-size: 72px;
    margin: 12px 0;
}

.stats {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1 1 auto;
}

.stat-line {
    width: 100%;
    height: 100%;
    display: flex;

    div {
        flex-grow: 1;
        margin: 0 20px;
        display: inline-block;
    }

    :last-child {
        text-align: right;
    }
}

.slider::-webkit-scrollbar {
    width: 0 !important;
    display: none;
}

.slider {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

.slider {
    /* line them up horizontally */
    display: flex;
    /* allow for scrolling */
    overflow-x: auto;
    /* make it smooth on iOS */
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }

    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox

    .slide {
        /* make sure the width is honored */
        flex-shrink: 0;
        scroll-snap-align: start;
        scroll-snap-stop: always;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        flex-flow: column;

        &:last-child .response {
            margin-right: 15px;
        }
    }
}

.summary-dots {
    text-align: center;
    margin-bottom: 14px;
}

.dot {
    width: 10px;
    height: 10px;
    background: #000;
    opacity: 0.25;
    display: inline-block;
    border-radius: 10px;
    margin: 0 5px;

    &.active {
        background: #fff;
        opacity: 1;
    }
}

.spinner {
    position: relative;
    top: 50%;
}
</style>
