<template>
    <div class="huddle-detail-card">
        <div class="section section--top">
            <div class="panel panel--flight">
                <Typography variant="caption">
                    {{ huddle.flight_number }}
                </Typography>
                <div class="flight-path">
                    {{ huddle.depart_station }}
                    <div class="barbell">
                        <div class="circle" />
                        <div class="line" />
                        <div class="circle" />
                    </div>
                    {{ huddle.arrival_station }}
                </div>
            </div>
            <div class="panel panel--goals">
                <HuddlesMetric
                    top-label="CSAT"
                    :metric-value="`${huddle.cxscore}%`"
                    :bottom-label="goalComparison"
                    :value-color="palette.successGreen"
                />
                <HuddlesMetric
                    top-label="Sales Goal"
                    :metric-value="`$${huddle.revenue_goal}`"
                    :bottom-label="salesPerHead"
                    :value-color="palette.successGreen"
                />
            </div>
        </div>

        <div class="section section--bottom">
            <div class="panel panel--insights">
                <HuddleDetailsTopicsPanel
                    :topics="huddle.topics"
                    :base-cxscore-goal="baseCxscoreGoal"
                    :summary-view="summaryView"
                />
            </div>
            <div class="panel panel--sales">
                <HuddleDetailsSalesPanel
                    :top-selling-items="huddle.top_selling_items"
                    :summary-view="summaryView"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Huddle } from '@/entities/huddles'
import HuddleDetailsTopicsPanel from '@/mobile/src/views/appV4/huddles/HuddleDetailsTopicsPanel.vue'
import HuddleDetailsSalesPanel from '@/mobile/src/views/appV4/huddles/HuddleDetailsSalesPanel.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import HuddlesMetric from '@/mobile/src/views/appV4/huddles/HuddlesMetric.vue'
import { palette } from '@/utils/palette'
import { formatNumber } from '@/utils/number'

interface Props {
    huddle: Huddle
    baseCxscoreGoal: number | undefined
    summaryView?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    summaryView: false,
})

const goalComparison = computed(() => {
    const difference = props.huddle.cxscore - props.baseCxscoreGoal

    if (difference === 0) {
        return 'On goal'
    }

    const differenceLabel = formatNumber(difference, 1)
    return `${differenceLabel}% ${difference > 0 ? 'above' : 'below'} goal`
})

const salesPerHead = computed(() => {
    if (!props.huddle.sales_per_head_goal) {
        return '-'
    }
    return `$${Number(props.huddle.sales_per_head_goal).toFixed(2)} per head`
})
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';

.huddle-detail-card {
    display: flex;
    flex-direction: column;
}

.section {
    padding: @spacing-2xl @spacing-3xl;
    display: flex;
    gap: @spacing-3xl;

    &--top {
        border-bottom: 1px solid var(--p-gray-200);
    }
}

.panel {
    flex: 1;

    &--flight {
        .flight-path {
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;
            color: var(--p-gray-900);

            display: flex;
            gap: 12px;
            align-items: center;

            .barbell {
                width: 100%;
                display: flex;
                align-items: center;
                .circle {
                    height: 8px;
                    min-width: 8px;
                    border-radius: 100px;
                    background: var(--p-gray-300);
                }
                .line {
                    height: 2px;
                    background: var(--p-gray-300);
                    width: 100%;
                    flex: 2;
                }
            }
        }
    }

    &--goals {
        display: flex;

        > * {
            flex: 1;
        }
    }
}
</style>
