<template>
    <div class="feedback-list-wrapper">
        <FeedbackResponseCard
            v-for="response in responses"
            :key="response.id"
            :response="response"
            @toggleStarred="onToggleStarred"
            @viewDetail="viewDetail"
        />
        <div v-if="loadingResponses || forceShowLoading" class="loading">
            <LoadingCard
                v-for="i in 3"
                :key="i"
                :borderless="true"
                variant="single"
                class="loading-single"
            />
        </div>
        <div
            v-if="!loadingResponses && resultsDepleted"
            class="no-more-results"
        >
            no more results
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import FeedbackResponseCard from '@/mobile/src/components/appV4/feedback/FeedbackResponseCard.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { Dict, IResponse } from '@/entities'
import { Action, Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import { getResponses } from '@/api/responses'
import { OFFLINE_RESPONSE_COUNT } from '@/store/modules/scorecard'
import { dedupBy } from '@/utils/array'

@Component({
    components: {
        LoadingCard,
        FeedbackResponseCard,
    },
    emits: ['refresh-finished'],
})
export default class FeedbackResponses extends Vue {
    @Prop({ type: Boolean, default: false }) public shouldRefresh!: boolean

    // Force the loading card to render a loading state.
    @Prop({ type: Boolean, default: false }) public forceShowLoading?: boolean

    @Getter public scorecard!: ScorecardEntity
    @Getter public responsesMap!: Dict<IResponse[]>
    @Getter public mobileFilterActive
    @Getter public mobileQuestionType!: string
    @Getter public hasNetworkConnection!: boolean
    @Getter public offlineFilter
    @Getter public topicFeedbackFilters
    @Getter public topicFeedbackTimeOption
    @Action public resetTopicFeedbackFilters
    @Action public resetTopicFeedbackTimeOption

    @Action public setResponses
    @Action public toggleResponseStar

    @Action public saveScrollPosition

    public resultsDepleted = false // whether we have more responses
    public loadingResponses = false
    public pageContentEl!: Element | null
    public feedbackResponseClicked = false

    private route = useRoute()
    private router = useRouter()

    public get key() {
        // for scorecard locked admin, responses on dashboard and scorecard page are different
        return JSON.stringify(this.prepareFilters)
    }

    public async loadMoreResponses() {
        if (
            !this.mobileFilterActive ||
            this.resultsDepleted ||
            this.loadingResponses
        ) {
            return
        }

        const filters = this.prepareFilters

        this.loadingResponses = true
        const { data } = await getResponses(filters, {
            pagesize: this.hasNetworkConnection ? 10 : OFFLINE_RESPONSE_COUNT,
            offset:
                this.responses && this.hasNetworkConnection
                    ? this.responses.length
                    : 0,
        })

        if (data && data.length > 0) {
            if (!this.responsesMap[this.key]) {
                this.setResponses({ key: this.key, data })
            } else {
                this.setResponses({
                    key: this.key,
                    data: dedupBy(
                        [...this.responsesMap[this.key], ...data],
                        ({ id }) => id.toString()
                    ),
                })
            }
        } else {
            this.resultsDepleted = true
        }
        this.loadingResponses = false
    }

    private get prepareFilters() {
        const filters = { ...this.mobileFilterActive }
        if (this.topicFeedbackFilters) {
            filters['filter_rules'] = this.topicFeedbackFilters
        }
        if (this.topicFeedbackTimeOption) {
            filters['time_unit'] = this.topicFeedbackTimeOption['time_unit']
            filters['time_value'] = this.topicFeedbackTimeOption['time_value']
        }

        return filters
    }

    public async refreshResponses() {
        if (!this.mobileFilterActive) {
            return
        }

        const filters = this.prepareFilters

        if (this.pageContentEl) {
            this.pageContentEl.scrollTo(0, 0)
        }
        this.setResponses({ key: this.key, data: [] })
        this.loadingResponses = true
        this.resultsDepleted = false
        const { data } = await getResponses(
            this.hasNetworkConnection
                ? filters
                : this.offlineFilter(this.mobileQuestionType),
            {
                pagesize: this.hasNetworkConnection
                    ? 10
                    : OFFLINE_RESPONSE_COUNT,
                offset: 0,
            }
        )

        if (data?.length > 0) {
            this.setResponses({ key: this.key, data })
        } else {
            this.resultsDepleted = true
        }
        this.loadingResponses = false
    }

    private get responses() {
        const responses = this.responsesMap[this.key]
        return responses && Array.isArray(responses)
            ? dedupBy(responses, ({ id }) => id.toString())
            : []
    }

    public handleScroll() {
        if (!this.pageContentEl) {
            return
        }

        // If we have reached the bottom of the page
        if (
            Math.ceil(
                this.pageContentEl.scrollTop + this.pageContentEl.clientHeight
            ) >= this.pageContentEl.scrollHeight
        ) {
            this.loadMoreResponses()
        }
    }

    public mounted() {
        if (!this.responsesMap[this.key]) {
            this.loadMoreResponses()
        } else if (this.topicFeedbackFilters) {
            this.refreshResponses()
        }

        // Add infinite scroll listener
        this.pageContentEl = document.querySelector('#page-content')
        if (!this.pageContentEl) {
            return
        }
        this.pageContentEl.addEventListener('scroll', this.handleScroll)
    }

    public beforeUnmount() {
        if (this.pageContentEl) {
            this.pageContentEl.removeEventListener('scroll', this.handleScroll)
        }

        // Don't reset the filters if user is navigating to view a feedback detail
        if (this.topicFeedbackFilters && !this.feedbackResponseClicked) {
            this.resetTopicFeedbackFilters()
            this.resetTopicFeedbackTimeOption()
        }
    }

    @Watch('shouldRefresh')
    public async forceRefresh() {
        if (this.shouldRefresh) {
            await this.refreshResponses()
            this.$emit('refresh-finished')
        }
    }

    public async onToggleStarred(data) {
        await this.toggleResponseStar({
            key: this.key,
            questionId: Number(data.questionId),
        })
    }

    public async viewDetail(id: number) {
        if (!this.hasNetworkConnection) {
            return
        }

        const pageContent = document.getElementById(
            'page-content'
        ) as HTMLElement
        await this.saveScrollPosition({
            page: this.route.name,
            scrollTop: pageContent?.scrollTop ?? 0,
        })

        this.feedbackResponseClicked = true

        await this.router.push({
            name: 'feedbackdetail',
            params: { feedbackid: id.toString() },
        })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';

.loading {
    margin-top: @featureGutter;

    .loading-single {
        margin-bottom: @featureGutter;
    }
}

.no-more-results {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    padding: 10px;
    font-size: 12px;
    font-style: italic;
}

.feedback-list-wrapper {
    div:first-child {
        margin-top: @featureGutterThick;
    }
}
</style>
