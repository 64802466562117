<template>
    <div class="huddles-card huddles-overview-stats">
        <div class="section section--top">
            <div class="panel">
                <HuddlesMetric
                    :top-label="cxmetric"
                    :metric-value="displayCxScore(baseGoals.cxscore)"
                    :value-color="palette.pGray900"
                    bottom-label="Last 30 days"
                />
                <HuddlesMetric
                    :top-label="`${cxmetric} Goal`"
                    :metric-value="displayCxScore(baseGoals.cxscore_goal)"
                    :value-color="palette.successGreen"
                    :bottom-label="displayCxScoreGoalRange"
                />
            </div>
        </div>
        <div class="section">
            <div class="panel">
                <HuddlesMetric
                    :top-label="`${baseGoals.last_month} Sales`"
                    :metric-value="
                        displaySalesDollarValue(
                            baseGoals.last_month_sales_per_head
                        )
                    "
                    :value-color="palette.pGray900"
                />
                <HuddlesMetric
                    top-label="Spend per head"
                    :metric-value="
                        displaySalesDollarValue(baseGoals.sales_per_head_goal)
                    "
                    :value-color="palette.successGreen"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { palette } from '@/utils/palette'
import HuddlesMetric from '@/mobile/src/views/appV4/huddles/HuddlesMetric.vue'
import { BaseGoals } from '@/entities/huddles'

interface Props {
    baseGoals: BaseGoals
    cxscoreAsPercentage: boolean
    cxmetric?: string
}
const props = withDefaults(defineProps<Props>(), {
    cxscoreAsPercentage: false,
    cxmetric: 'CSAT',
})

const displayCxScore = (cxscore: number | undefined): string => {
    if (cxscore === undefined) {
        return '-'
    }
    return `${cxscore}${props.cxscoreAsPercentage ? '%' : ''}`
}

const displayCxScoreGoalRange = computed(() => {
    return props.baseGoals.cxscore_goal_range
        ? 'Year ' + props.baseGoals.cxscore_goal_range
        : ''
})

const displaySalesDollarValue = (amount: number): string => {
    if (!amount) {
        return '-'
    }
    return `$${amount}`
}
</script>

<style lang="less" scoped>
@import '@/styles/prime-vue/variables.less';
@import '@/styles/rain/variables.less';

.huddles-card {
    &.huddles-overview-stats {
        padding: 0;
    }
}

.section {
    padding: @spacing-2xl @spacing-3xl;
    display: flex;
    gap: @spacing-3xl;
    &--top {
        border-bottom: 1px solid var(--p-gray-200);
    }
}

.panel {
    display: flex;
    flex: 1;
    > * {
        flex: 1;
    }
}
</style>
